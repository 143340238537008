import { Outlet } from "react-router-dom";

const LayoutRoot = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default LayoutRoot;
